<template>
  <el-row :gutter="20" style="margin-top: 10px; flex: 1">
    <el-col :span="3">
      <el-select
        v-model="modelId"
        placeholder="模型选择"
        @change="handleSelectChange"
      >
        <el-option
          v-for="item in modelList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="3">
      <el-select
        v-model="materialIds"
        multiple
        collapse-tags
        placeholder="原材料选择"
        @change="handleSelectChange"
      >
        <el-option
          v-for="item in materialList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="7">
      <el-date-picker
        v-model="date"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </el-col>
    <el-col :span="2">
      <el-button type="primary" @click="handleAddLoss('material')"
        >新增材料损耗</el-button
      >
    </el-col>
    <el-col :span="2">
      <el-button type="primary" @click="handleAddLoss('prod')"
        >新增产品损耗</el-button
      >
    </el-col>
  </el-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "FilterSelect",
  data() {
    return {
      modelId: null,
      materialIds: null,
      materialList: [],
      date: "",
      startTime: "",
      endTime: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(["modelList"]),
    paramWatch() {
      return {
        modelId: this.modelId,
        materialIds: this.materilIds,
        date: this.date,
      };
    },
  },
  watch: {
    modelId() {
      this.getMaterials();
    },
    date(e) {
      if (e) {
        this.startTime = Math.floor(e[0].getTime() / 1000);
        this.endTime = Math.floor(e[1].getTime() / 1000);
      }
    },
    paramWatch() {
      this.handleSelectChange();
    },
  },
  created() {
    this.handleInit();
  },
  methods: {
    async handleInit() {
      await this.$store.dispatch("getGoodsModels");
      this.modelId = this.modelList[0].id;
      this.handleSelectChange();
    },
    //新增损耗
    handleAddLoss(type) {
      this.$emit("addLoss", type);
    },
    //获取原材料列表
    async getMaterials() {
      this.materialList = await this.$store.dispatch("getMaterials", {
        model_id: this.modelId,
      });
      this.materialIds = [this.materialList[0].id];
    },
    //筛选改变
    handleSelectChange() {
      if (this.modelId && this.materialIds && this.startTime) {
        this.$emit("change", {
          modelId: this.modelId,
          materialIds: this.materialIds,
          startTime: this.startTime,
          endTime: this.endTime,
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
</style>
