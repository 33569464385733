<template>
  <table-container :list="list" height="750">
    <el-table-column type="index" label="排序" align="center" width="50">
    </el-table-column>
    <el-table-column
      prop="material_name"
      label="原材料"
      align="center"
      :filters="filterArrs.materials"
      :filter-method="handleFilterMaterial"
      filter-placement="bottom-end"
    >
    </el-table-column>
    <el-table-column
      prop="goods_title"
      label="产品"
      align="center"
      :filters="filterArrs.prods"
      :filter-method="handleFilterProd"
      filter-placement="bottom-end"
    >
    </el-table-column>
    <el-table-column
      prop="7days_usage"
      label="品牌"
      align="center"
      :filters="filterArrs.brands"
      :filter-method="handleFilterBrand"
      filter-placement="bottom-end"
    >
      <template slot-scope="scope">
        {{ scope.row.specAttrs[0].parent.brand_name }}
      </template>
    </el-table-column>
    <el-table-column
      label="型号"
      align="center"
      :filters="filterArrs.types"
      :filter-method="handleFilterType"
      filter-placement="bottom-end"
    >
      <template slot-scope="scope">
        {{ scope.row.specAttrs[0].value_cn }}
      </template>
    </el-table-column>
    <el-table-column
      prop="sup_name"
      label="供应商"
      align="center"
      :filters="filterArrs.sups"
      :filter-method="handleFilterSup"
      filter-placement="bottom-end"
    >
    </el-table-column>
    <el-table-column prop="num" label="数量" align="center"> </el-table-column>
    <el-table-column
      prop="wastage_type_name"
      label="损耗原因"
      align="center"
      :filters="filterArrs.wastage_types"
      :filter-method="handleFilterWastageTypes"
      filter-placement="bottom-end"
    >
    </el-table-column>
    <el-table-column prop="memo" label="备注" align="center"> </el-table-column>
    <el-table-column prop="created_at" label="时间" align="center">
    </el-table-column>
    <!-- <el-table-column prop="supplier_23" label="操作"> </el-table-column> -->
  </table-container>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
  },
  data() {
    return {
      materialIds: [],
      filterArrs: {},
    };
  },
  watch: {
    list() {
      this.handleFilterArr();
    },
  },
  methods: {
    //获取筛选数组
    handleFilterArr() {
      this.filterArrs = this.list.reduce(
        (prev, cur) => {
          let obj = prev;
          obj.materials = this.handelSomePush(obj.materials, {
            value: cur.material_id,
            text: cur.material_name,
          });
          obj.prods = this.handelSomePush(obj.prods, {
            value: cur.goods_id,
            text: cur.goods_title,
          });
          obj.types = this.handelSomePush(obj.types, {
            value: cur.specAttrs[0].value_en,
            text: cur.specAttrs[0].value_cn,
          });
          obj.brands = this.handelSomePush(obj.brands, {
            value: cur.specAttrs[0].parent.brand_id,
            text: cur.specAttrs[0].parent.brand_name,
          });
          obj.sups = this.handelSomePush(obj.sups, {
            value: cur.sup_id,
            text: cur.sup_name,
          });
          obj.wastage_types = this.handelSomePush(obj.wastage_types, {
            value: cur.wastage_type_id,
            text: cur.wastage_type_name,
          });
          return obj;
        },
        {
          materials: [],
          prods: [],
          types: [],
          brands: [],
          sups: [],
          wastage_types: [],
        }
      );
    },
    //判断添加
    handelSomePush(arr, { value, text }) {
      if (!arr.some((item) => item.value === value)) {
        arr.push({
          value,
          text,
        });
      }
      return arr;
    },
    //过滤原材料
    handleFilterMaterial(value, row) {
      return row.material_id === value;
    },
    //过滤产品
    handleFilterProd(value, row) {
      return row.goods_id === value;
    },
    handleFilterBrand(value, row) {
      return row.specAttrs[0].parent.brand_id === value;
    },
    handleFilterType(value, row) {
      return row.specAttrs[0].value_en === value;
    },
    handleFilterSup(value, row) {
      return row.sup_id === value;
    },
    handleFilterWastageTypes(value, row) {
      return row.wastage_type_id === value;
    },
  },
};
</script>
<style lang="less" scoped>
</style>