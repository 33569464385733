
<template>
  <div class="wrapper1">
    <filter-select
      @change="handleSelectChange"
      @addLoss="handleAddLoss"
    ></filter-select>
    <list :list="lossList"></list>
    <add-loss
      :modelList="modelList"
      :type="type"
      :show="show"
      @visible="handleShowChange"
    >
    </add-loss>
  </div>
</template>

<script>
import FilterSelect from "./components/FilterSelect";
import List from "./components/List";
import AddLoss from "./components/AddLoss";
import { mapState } from "vuex";
export default {
  name: "LossList",
  components: {
    FilterSelect,
    List,
    AddLoss,
  },
  data() {
    return {
      title: "产品入库",
      addType: "material",
      isLiberty: false,
      show: false,
      specId: null,
      supId: null,
      materialIds: null,
      inventoryList: [],
      type: "material",
      show: false,
      lossList: [],
    };
  },
  computed: {
    ...mapState(["modelList"]),
  },
  watch: {},
  methods: {
    //筛选改变
    handleSelectChange(param) {
      this.modelId = param.modelId;
      this.materialIds = param.materialIds;
      this.startTime = param.startTime;
      this.endTime = param.endTime;
      this.getLossList();
    },
    //新增损耗
    handleAddLoss(type) {
      this.type = type;
      this.show = true;
    },
    async getLossList() {
      const res = await this.$store.dispatch("getLossList", {
        material_ids: JSON.stringify(this.materialIds),
        startTime: this.startTime,
        endTime: this.endTime,
      });
      this.lossList = res.items;
    },
    //显示隐藏弹窗
    handleShowChange(e) {
      this.show = e;
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper1 {
  width: 100%;
  height: 100%;
  font-size: 12px;
}
</style>