<template>
  <el-dialog
    :title="type === 'prod' ? '新增产品损耗' : '新增原材料损耗'"
    :visible.sync="visible"
    append-to-body
    width="30%"
  >
    <el-form label-width="80px">
      <el-form-item label="原材料:" v-if="type === 'material'">
        <el-select v-model="materialId" placeholder="原材料选择">
          <el-option
            v-for="(item, index) in materialList"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模型:">
        <el-select v-model="modelId" placeholder="模型选择">
          <el-option
            v-for="item in modelList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品:">
        <el-select v-model="prodId" placeholder="模型选择">
          <el-option
            v-for="item in prodList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="品牌:">
        <el-select v-model="brandId" placeholder="选择品牌">
          <el-option
            v-for="item in brandList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="规格:">
        <el-select v-model="specId" placeholder="规格选择">
          <el-option
            v-for="(item, index) in specList"
            :key="index"
            :label="item.essentialAttrs[0].value_cn"
            :value="item.spec_id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="供应商:">
        <el-select v-model="supId" placeholder="供应商">
          <el-option
            v-for="item in supList"
            :key="item.sup_id"
            :label="item.name"
            :value="item.sup_id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="损耗类型:">
        <el-select v-model="lossTypeId" placeholder="损耗类型">
          <el-option
            v-for="item in lossTypeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="数量:">
        <el-col :span="14">
          <el-input
            type="text"
            v-model="num"
            :minlength="1"
            placeholder="数量"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="备注:">
        <el-col :span="20">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="memo"
          >
          </el-input>
        </el-col>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleComfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "AddLoss",
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "prod",
    },
  },
  data() {
    return {
      visible: false,
      materialList: [],
      materialId: null,
      modelId: null,
      prodId: null,
      prodList: [],
      brandId: null,
      specId: null,
      specList: [],
      supId: null,
      supList: [],
      lossTypeId: null,
      lossTypeList: [],
      num: 1,
      memo: "",
    };
  },
  computed: {
    ...mapState(["modelList", "brandList"]),
    specWatchParam() {
      return {
        modelId: this.modelId,
        prodId: this.prodId,
        brandId: this.brandId,
      };
    },
  },
  watch: {
    show(e) {
      this.visible = e;
    },
    visible(e) {
      this.$emit("visible", e);
    },
    specWatchParam() {
      this.getSpecList();
    },
    specId(e) {
      this.getSupList(e);
    },
  },
  created() {
    this.handleInit();
  },
  methods: {
    //初始化
    handleInit() {
      this.getGoodsModels();
      this.getBrandList();
      this.getMaterials();
      this.getAllLossType();
    },
    //获取模型列表
    async getGoodsModels() {
      await this.$store.dispatch("getGoodsModels");
      this.modelId = this.modelList[0].id;
      this.getModelFindProd();
    },
    //获取产品列表
    async getModelFindProd() {
      this.prodList = await this.$store.dispatch("getModelFindProd", {
        model_id: this.modelId,
      });
      this.prodId = this.prodList[0].id;
    },

    //获取品牌列表
    async getBrandList() {
      await this.$store.dispatch("getAllBrandList");
      this.brandId = this.brandList[0].id;
    },
    //获取规格列表
    async getSpecList() {
      if (!this.modelId || !this.brandId || !this.prodId) {
        return;
      }
      const data = {
        model_id: this.modelId,
        listfield_filter: JSON.stringify({ brand_id: this.brandId }),
        goods_id: this.prodId,
      };
      const res = await this.$store.dispatch("getSpeAttrcList", data);
      this.specList = res.specs;
    },
    //获取供应商列表
    getSupList(specId) {
      const item = this.specList.find((item) => {
        return item.spec_id === specId;
      });
      this.supList = item.suppliers;
    },
    //获取原材料列表
    async getMaterials() {
      this.materialList = await this.$store.dispatch("getMaterials", {
        model_id: this.modelId,
      });
      this.materialId = this.materialList[0].id;
    },
    //获取损耗类型列表
    async getAllLossType() {
      const res = await this.$store.dispatch("getAllLossType");
      this.lossTypeList = res.types;
    },
    //提交
    handleComfirm() {
      if (
        !this.materialId ||
        !this.specId ||
        !this.supId ||
        !this.lossTypeId ||
        this.num <= 0
      ) {
        return;
      }
      if (this.type === "prod") {
        this.handleProdLossComfirm();
      } else {
        this.handleMaterialLossComfirm();
      }
    },
    //新增产品损耗提交
    async handleProdLossComfirm() {
      const res = await this.$store.dispatch("addProdLoss", {
        spec_id: this.specId,
        sup_id: this.supId,
        wastage_type: this.lossTypeId,
        num: this.num,
        memo: this.memo,
      });
      this.$tool.comfirmToast(res);
    },
    //新增原材料损耗提交
    async handleMaterialLossComfirm() {
      const res = await this.$store.dispatch("addMaterialLoss", {
        material_id: this.materialId,
        spec_id: this.specId,
        sup_id: this.supId,
        wastage_type: this.lossTypeId,
        num: this.num,
        memo: this.memo,
      });
      this.$tool.comfirmToast(res).then(() => {
        this.visible = false;
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>